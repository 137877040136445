<template>
    <v-container>
        <vue-draggable-resizable :h="600" :w="350">
            <BT-Blade title="Menu" hideBackButton>
                <template>
                    <v-expansion-panels>

                        <BT-Blade-Expansion-Items
                            :canExportCSV="false"
                            :dividers="false"
                            :headers="[{ text: 'Name', value: 'userName', title: 1 }]"
                            :itemProperties="['ID','UserName']"
                            navigation="users"
                            :onSelect="(b, item) => { openUserBox(item) }"
                            showList
                            title="Users" />

                    </v-expansion-panels>
                </template>
            </BT-Blade>
        </vue-draggable-resizable>

        <BT-Board-Drop-Box
            v-for="(block, index) in blocks"
            @close="closeDropBox"
            @select="selectBox"
            :key="'d' + index"
            :idProp="block.idProp"
            :item="block.item"
            :itemText="block.itemText"
            :navigation="block.navigation"
            :onDrop="block.onDrop"
            :onFilter="block.onFilter"
            :onPull="block.onPull"
            :onRemove="block.onRemove"
            :sortProp="block.sortProp"
            :titleProp="block.titleProp" />

        <BT-Board-Supply-Box
            v-for="(block, index) in supplyBlocks"
            @close="closeSupplyBox"
            :canInsert="block.canInsert"
            :key="'s' + index"
            :idProp="block.idProp"
            :itemText="block.itemText"
            :navigation="block.navigation"
            :onFilter="block.onFilter"
            :sortProp="block.sortProp"
            :title="block.title" />

    </v-container>
</template>

<script>
export default {
    name: 'User-Board',
    components: {
        BTBoardDropBox: () => import('~components/BT-Board-Drop-Box.vue'),
        BTBoardSupplyBox: () => import('~components/BT-Board-Supply-Box.vue'),
        VueDraggableResizable: () => import('vue-draggable-resizable')
    },
    data: function() {
        return {
            blocks: [],
            supplyBlocks: [],
            existingRoles: [],
        }
    },
    methods: {
        closeDropBox({ id }) {
            var ind = this.blocks.findIndex(x => x.item.id == id);
            if (ind > -1) {
                var nav = this.blocks[ind].navigation;
                this.blocks.splice(ind, 1);

                if (!this.blocks.some(y => y.navigation == nav)) {
                    this.closeSupplyBox({ navigation: nav });
                }
            }
        },
        closeSupplyBox({ navigation }) {
            var ind = this.supplyBlocks.findIndex(x => x.navigation == navigation);
            if (ind > -1) {
                this.supplyBlocks.splice(ind, 1);

                if (this.blocks.some(y => y.navigation == navigation)) {
                    this.blocks = this.blocks.filter(x => x.navigation != navigation);
                }
            }
        },
        // openBox(item, idProp, navigation = 'products', sortProp = 'productName', titleProp = 'groupName') {
        //     this.blocks = this.blocks.filter(x => x.idProp == idProp && x.navigation == navigation);
            
        //     if (!this.blocks.some(y => y.item.id == item.id)) {
        //         this.blocks.push({ item, idProp, navigation, sortProp, titleProp });
        //     }

        //     var supplyBlock = this.supplyBlocks.find(y => y.navigation == navigation);
        //     if (supplyBlock == null) {
        //         supplyBlock = {
        //             idProp: idProp,
        //             canInsert: true,
        //             itemText: sortProp,
        //             navigation: navigation,
        //             sortProp: sortProp,
        //             title: this.$BlitzIt.navigation.findDisplayName(navigation),
        //         }

        //         this.supplyBlocks.push(supplyBlock);
        //     }
        //     else {
        //         supplyBlock.idProp = idProp;
        //     }
        // },
        openUserBox(item) { //, idProp, navigation = 'products', sortProp = 'productName', titleProp = 'groupName') {
            var idProp = 'userID'; //'productOfferingGroupID';
            var navigation = 'roles';
            var sortProp = 'roleName';
            var titleProp = 'userName';
            var itemText = 'roleName';

            this.blocks = this.blocks.filter(x => x.idProp == idProp && x.navigation == navigation);
            
            if (!this.blocks.some(y => y.item.id == item.id)) {
                this.blocks.push({
                    item,
                    idProp,
                    itemText,
                    navigation,
                    onDrop: async(navigation, itemToAdd, group, list) => {
                        if (list.some(y => y.id == itemToAdd.id)) {
                            return;
                        }

                        await this.$BlitzIt.api.post(
                            'roles',
                            { userID: group.id, roleID: itemToAdd.id },
                            null,
                            '/post/AddRole');

                        list.push(itemToAdd);
                    },
                    onFilter: list => { return list },
                    onPull: async(nav, item) => {
                        var res = await this.$BlitzIt.store.get('users', item.id);
                        return res.roles;
                    },
                    onRemove: async(group, itemToRemove, list) => {
                        if (itemToRemove == null || group == null) {
                            return;
                        }

                        await this.$BlitzIt.api.post(
                            'roles',
                            { userID: group.id, roleID: itemToRemove.id },
                            null,
                            '/post/RemoveRole');

                        var ind = list.findIndex(y => y.id == itemToRemove.id);
                        if (ind > -1) {
                            list.splice(ind, 1);
                        }
                    },
                    sortProp,
                    titleProp
                });
            }

            var supplyBlock = this.supplyBlocks.find(y => y.navigation == navigation);
            if (supplyBlock == null) {
                supplyBlock = {
                    idProp: idProp,
                    canInsert: false,
                    itemText: itemText,
                    navigation: navigation,
                    onFilter: list => {
                        return list.filter(l => !this.existingRoles.some(z => z.id == l.id))
                    },
                    sortProp: sortProp,
                    title: this.$BlitzIt.navigation.findDisplayName(navigation),
                }

                this.supplyBlocks.push(supplyBlock);
            }
            else {
                supplyBlock.idProp = idProp;
            }
        },
        selectBox({ items }) {
            this.existingRoles = items;
        }
    }
}
</script>